import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import '../css/dbh.css';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement, ArcElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement, ArcElement);

const DashboardDemo = () => {
  const [timeRange, setTimeRange] = useState('week');

  const lineChartData = {
    labels: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
    datasets: [
      {
        label: 'Ventas',
        data: [12, 19, 3, 5, 2, 3, 9],
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const barChartData = {
    labels: ['Producto A', 'Producto B', 'Producto C', 'Producto D', 'Producto E'],
    datasets: [
      {
        label: 'Unidades Vendidas',
        data: [12, 19, 3, 5, 2],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnutChartData = {
    labels: ['Rojo', 'Azul', 'Amarillo', 'Verde', 'Púrpura'],
    datasets: [
      {
        label: 'Tráfico por Fuente',
        data: [12, 19, 3, 5, 2],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="dashboard-demo">
      <div className="dashboard-header">
        <h3>AnalyticsPro Dashboard</h3>
        <div className="time-range-selector">
          {['day', 'week', 'month', 'year'].map(range => (
            <button 
              key={range}
              className={timeRange === range ? 'active' : ''}
              onClick={() => setTimeRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
      </div>
      <div className="dashboard-content">
        <div className="metric-cards">
          <motion.div className="metric-card" whileHover={{ scale: 1.05 }}>
            <h4>Ventas Totales</h4>
            <p>$12,345</p>
            <span className="metric-change positive">+15%</span>
          </motion.div>
          <motion.div className="metric-card" whileHover={{ scale: 1.05 }}>
            <h4>Nuevos Usuarios</h4>
            <p>1,234</p>
            <span className="metric-change positive">+8%</span>
          </motion.div>
          <motion.div className="metric-card" whileHover={{ scale: 1.05 }}>
            <h4>Tasa de Conversión</h4>
            <p>3.45%</p>
            <span className="metric-change negative">-2%</span>
          </motion.div>
        </div>
        <div className="chart-row">
          <div className="chart-container">
            <h4>Tendencia de Ventas</h4>
            <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
          <div className="chart-container">
            <h4>Productos Más Vendidos</h4>
            <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </div>
        <div className="chart-row">
          <div className="chart-container">
            <h4>Tráfico por Fuente</h4>
            <Doughnut data={doughnutChartData} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
          <div className="chart-container">
            <h4>Actividad Reciente</h4>
            <ul className="activity-list">
              <li>Usuario A realizó una compra - 5 min ago</li>
              <li>Nuevo registro de Usuario B - 12 min ago</li>
              <li>Usuario C dejó una reseña - 27 min ago</li>
              <li>Producto X agotado - 1 hora ago</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDemo;