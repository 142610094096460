import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import '../css/appmvl.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

const FitnessAppDemo = () => {
  const [activeTab, setActiveTab] = useState('workout');
  const [workoutProgress, setWorkoutProgress] = useState(0);

  const tabs = ['workout', 'nutrition', 'progress'];

  const incrementProgress = () => {
    setWorkoutProgress(prev => Math.min(prev + 20, 100));
  };

  const nutritionData = {
    labels: ['Proteínas', 'Carbohidratos', 'Grasas'],
    datasets: [
      {
        data: [30, 50, 20],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
      }
    ]
  };

  const progressData = {
    labels: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
    datasets: [
      {
        label: 'Peso (kg)',
        data: [80, 79, 78, 76],
        borderColor: '#FF6384',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ]
  };

  return (
    <div className="fitness-app-demo">
      <div className="phone-frame">
        <div className="app-header">
          <h3>FitTrack Pro</h3>
        </div>
        <div className="app-content">
          {activeTab === 'workout' && (
            <div className="workout-screen">
              <h4>Hoy: Entrenamiento de Fuerza</h4>
              <ul>
                <li>Sentadillas 3x12</li>
                <li>Peso muerto 3x10</li>
                <li>Press de banca 3x10</li>
                <li>Remo con barra 3x12</li>
              </ul>
              <motion.button 
                whileTap={{ scale: 0.95 }}
                onClick={incrementProgress}
              >
                Completar ejercicio
              </motion.button>
              <div className="progress-bar">
                <div className="progress" style={{width: `${workoutProgress}%`}}></div>
              </div>
            </div>
          )}
          {activeTab === 'nutrition' && (
            <div className="nutrition-screen">
              <h4>Plan de Nutrición</h4>
              <ul>
                <li>Desayuno: Avena con frutas</li>
                <li>Almuerzo: Pechuga de pollo con ensalada</li>
                <li>Cena: Salmón con vegetales al vapor</li>
              </ul>
              <div className="nutrition-chart">
                <Doughnut data={nutritionData} />
              </div>
            </div>
          )}
          {activeTab === 'progress' && (
            <div className="progress-screen">
              <h4>Tu Progreso</h4>
              <div className="progress-chart">
                <Line data={progressData} />
              </div>
              <p>¡Has mejorado un 15% este mes!</p>
            </div>
          )}
        </div>
        <div className="app-footer">
          {tabs.map(tab => (
            <button 
              key={tab} 
              className={activeTab === tab ? 'active' : ''}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FitnessAppDemo;