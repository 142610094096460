import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FaCode, FaMobileAlt, FaCloud, FaRobot, FaSearch, FaWhatsapp, FaShieldAlt, FaChartLine, FaTools, FaDatabase } from 'react-icons/fa';
import '../styles/ServicesPage.css';
import { useNavigate } from 'react-router-dom';

const CTAButton = ({ text = "Contactar" }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contacto');
  };

  return (
    <motion.button
      className="services-cta-button"
      onClick={handleClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {text}
    </motion.button>
  );
};

const ServiceCard = ({ icon, title, description, technologies }) => (
  <motion.div 
    className="service-card"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="service-icon" aria-hidden="true">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
    <div className="technologies">
      <h4>Tecnologías:</h4>
      <ul>
        {technologies.map((tech, index) => (
          <li key={index}>{tech}</li>
        ))}
      </ul>
    </div>
  </motion.div>
);

const ServicesPage = () => {
  const services = [
    {
      icon: <FaCode />,
      title: "Desarrollo Web Personalizado",
      description: "Creamos sitios web optimizados para SEO, rendimiento y experiencia de usuario, adaptados a las necesidades de tu negocio, maximizando la conversión de visitantes a clientes.",
      technologies: ["React", "Node.js", "GraphQL", "Next.js", "WordPress", "PHP"]
    },
    {
      icon: <FaMobileAlt />,
      title: "Desarrollo de Aplicaciones Móviles",
      description: "Diseñamos aplicaciones móviles nativas y multiplataforma, ideales para startups y grandes empresas que buscan destacar en el mercado digital.",
      technologies: ["React Native", "Flutter", "iOS (Swift)", "Android (Kotlin)", "Xamarin"]
    },
    {
      icon: <FaCloud />,
      title: "Soluciones Cloud y DevOps",
      description: "Implementamos infraestructuras en la nube escalables y optimizamos el ciclo de vida del software con soluciones DevOps avanzadas.",
      technologies: ["AWS", "Azure", "Google Cloud", "Docker", "Kubernetes", "Jenkins", "Terraform"]
    },
    {
      icon: <FaRobot />,
      title: "Inteligencia Artificial y Machine Learning",
      description: "Automatizamos procesos y mejoramos la toma de decisiones con sistemas de IA y aprendizaje automático adaptados a tu negocio.",
      technologies: ["TensorFlow", "PyTorch", "Scikit-learn", "Computer Vision", "NLP", "Deep Learning"]
    },
    {
      icon: <FaShieldAlt />,
      title: "Ciberseguridad",
      description: "Protegemos tus activos digitales con análisis de vulnerabilidades, pruebas de penetración y soluciones avanzadas de seguridad.",
      technologies: ["Pentesting", "Seguridad en la Nube", "Cifrado", "Respuesta a Incidentes"]
    },
    {
      icon: <FaChartLine />,
      title: "Consultoría Tecnológica",
      description: "Guiamos a tu negocio en su transformación digital, adoptando tecnologías innovadoras que mejoran la competitividad.",
      technologies: ["Estrategia Digital", "Optimización de Procesos", "Análisis de Datos", "Transformación Digital"]
    },
    {
      icon: <FaTools />,
      title: "Mantenimiento y Soporte",
      description: "Ofrecemos soporte técnico continuo y mantenimiento de sistemas para garantizar el óptimo funcionamiento de tus soluciones digitales.",
      technologies: ["Monitoreo 24/7", "Optimización de Rendimiento", "Actualizaciones de Seguridad", "Gestión de Incidencias"]
    },
    {
      icon: <FaDatabase />,
      title: "Gestión y Optimización de Bases de Datos",
      description: "Optimizamos el rendimiento y la seguridad de tus bases de datos empresariales con soluciones adaptadas a tus necesidades.",
      technologies: ["SQL", "NoSQL", "Big Data", "Data Warehousing", "MongoDB", "PostgreSQL", "Oracle"]
    },
    {
      icon: <FaSearch />,
      title: "Posicionamiento Web (SEO)",
      description: "Mejoramos tu visibilidad en buscadores mediante estrategias SEO efectivas, optimización técnica y contenido enfocado en atraer tráfico relevante.",
      technologies: ["SEO Local", "Optimización On-page", "Link Building", "Auditorías SEO", "SEO Técnico"]
    }
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "InnovativeCode",
    "description": "Empresa líder en desarrollo web, aplicaciones móviles y soluciones digitales innovadoras en Madrid y España.",
    "url": "https://www.innovativecode.es",
    "logo": "https://www.innovativecode.es/logo.png",
    "areaServed": {
      "@type": "Country",
      "name": "España"
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Servicios de Tecnología",
      "itemListElement": services.map((service, index) => ({
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": service.title,
          "description": service.description
        }
      }))
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+34-622-112-337",
      "contactType": "customer service",
      "email": "info@innovativecode.es",
      "areaServed": "ES",
      "availableLanguage": ["Spanish", "English"]
    }
  };

  return (
    <>
      <Helmet>
        <title>Servicios de Desarrollo Web y Software | InnovativeCode Madrid</title>
        <meta name="description" content="Descubre nuestros servicios de desarrollo web, aplicaciones móviles, IA, cloud y SEO en Madrid. Soluciones tecnológicas personalizadas para impulsar tu negocio digital." />
        <meta name="keywords" content="desarrollo web, aplicaciones móviles, inteligencia artificial, cloud computing, SEO, ciberseguridad, consultoría tecnológica, Madrid" />
        <link rel="canonical" href="https://www.innovativecode.es/servicios" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <main className="services-page">
        <h1>Servicios de Desarrollo Web y Software en Madrid</h1>
        <p className="services-intro">
          En InnovativeCode, ofrecemos soluciones tecnológicas innovadoras que impulsan tu negocio hacia el futuro digital. Nuestro equipo combina creatividad y experiencia técnica para diseñar soluciones que satisfacen tus necesidades empresariales.
        </p>
        <section className="services-grid">
          {services.map((service, index) => (
            <motion.article
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <ServiceCard {...service} />
            </motion.article>
          ))}
        </section>
        <section className="cta-section">
          <h2>¿Listo para llevar tu negocio al siguiente nivel tecnológico?</h2>
          <p>Contáctanos hoy para una consulta gratuita y descubre cómo nuestras soluciones pueden transformar tu negocio.</p>
          <CTAButton text='Solicitar Consulta Gratuita' />
        </section>
      </main>
      <a href="https://wa.me/34622112337" target="_blank" rel="noopener noreferrer" className="whatsapp-float" aria-label="Contactar por WhatsApp">
        <FaWhatsapp aria-hidden="true" />
      </a>
    </>
  );
};

export default ServicesPage;
