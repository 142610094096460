import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaMobileAlt, FaGlobe, FaRobot, FaWhatsapp, FaShoppingCart, FaChartLine, FaTasks } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FitnessAppDemo from './demos/tools/appmovilftn';
import LearningPlatformDemo from './demos/tools/LearningPlatformDemo';
import ChatbotDemo from './demos/tools/ChatbotDemo';
import EcommerceDemo from './demos/tools/EcommerceDemo';
import DashboardDemo from './demos/tools/DashboardDemo';
import AIProjectManagementDemo from './demos/tools/AIProjectManagementDemo';
import '../styles/ProjectsPage.css';
import { useNavigate } from 'react-router-dom';

const CTAButton = ({ text = "Contactar" }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contacto');
  };

  return (
    <motion.button
      className="cta-button"
      onClick={handleClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {text}
    </motion.button>
  );
};

const ProjectCard = ({ icon, title, description, onClick }) => (
  <motion.div 
    className="project-card"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
  >
    <div className="project-icon" aria-hidden="true">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
    <button className="demo-button">Ver Demo</button>
  </motion.div>
);

const ProjectDemo = ({ project, onClose }) => {
  const DemoComponent = project.demoComponent;
  return (
    <motion.div 
      className="project-demo"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <button className="close-button" onClick={onClose} aria-label="Cerrar demo">×</button>
      <h2>{project.title}</h2>
      <div className="demo-content">
        <DemoComponent />
      </div>
    </motion.div>
  );
};

const ProjectsPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { t } = useTranslation();

  const projects = [
    {
      icon: <FaMobileAlt />,
      title: t('projects.fitnessApp.title'),
      description: t('projects.fitnessApp.description'),
      demoComponent: FitnessAppDemo,
      technologies: ["React Native", "Firebase", "API RESTful", "Diseño UX/UI"]
    },
    {
      icon: <FaGlobe />,
      title: t('projects.learningPlatform.title'),
      description: t('projects.learningPlatform.description'),
      demoComponent: LearningPlatformDemo,
      technologies: ["React", "Node.js", "MongoDB", "WebRTC", "AWS"]
    },
    {
      icon: <FaRobot />,
      title: t('projects.chatbot.title'),
      description: t('projects.chatbot.description'),
      demoComponent: ChatbotDemo,
      technologies: ["Python", "TensorFlow", "NLP", "Integración API"]
    },
    {
      icon: <FaShoppingCart />,
      title: t('projects.ecommerce.title'),
      description: t('projects.ecommerce.description'),
      demoComponent: EcommerceDemo,
      technologies: ["Magento", "PHP", "MySQL", "Pasarela de pago", "SEO"]
    },
    {
      icon: <FaChartLine />,
      title: t('projects.dashboard.title'),
      description: t('projects.dashboard.description'),
      demoComponent: DashboardDemo,
      technologies: ["D3.js", "Vue.js", "GraphQL", "Tiempo real", "BigQuery"]
    },
    {
      icon: <FaTasks />,
      title: t('projects.projectManagement.title'),
      description: t('projects.projectManagement.description'),
      demoComponent: AIProjectManagementDemo,
      technologies: ["Machine Learning", "Python", "React", "Docker", "CI/CD"]
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": projects.map((project, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "SoftwareApplication",
        "name": project.title,
        "description": project.description,
        "applicationCategory": "BusinessApplication",
        "operatingSystem": "All",
        "offers": {
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "EUR"
        }
      }
    }))
  };

  return (
    <div className="projects-page">
      <Helmet>
        <title>{t('projects.pageTitle')} | Proyectos de Desarrollo Web y Apps en Madrid | InnovativeCode</title>
        <meta name="description" content={t('projects.pageDescription') + " Descubre cómo nuestras soluciones tecnológicas impulsan el éxito de empresas en Madrid y toda España."} />
        <meta name="keywords" content="proyectos desarrollo web, aplicaciones móviles, inteligencia artificial, e-commerce, dashboard, gestión de proyectos, Madrid" />
        <link rel="canonical" href="https://www.innovativecode.es/proyectos" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {t('projects.title')}
      </motion.h1>
      <motion.p
        className="projects-intro"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Explora nuestra cartera de proyectos innovadores en desarrollo web, aplicaciones móviles y soluciones de software personalizadas. Cada proyecto demuestra nuestro compromiso con la excelencia técnica y la innovación en Madrid y toda España.
      </motion.p>
      <motion.div 
        className="projects-grid"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {projects.map((project, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
          >
            <ProjectCard 
              {...project} 
              onClick={() => {
                setSelectedProject(project);
                ReactGA.event({
                  category: 'Demo',
                  action: 'Opened Demo',
                  label: project.title
                });
              }}
            />
          </motion.div>
        ))}
      </motion.div>
      <AnimatePresence>
        {selectedProject && (
          <ProjectDemo 
            project={selectedProject} 
            onClose={() => setSelectedProject(null)}
          />
        )}
      </AnimatePresence>
      <motion.div
        className="cta-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.7 }}
      >
        <h2>¿Inspirado por nuestros proyectos de desarrollo en Madrid?</h2>
        <p>Estos ejemplos muestran cómo nuestras soluciones de desarrollo web y aplicaciones móviles pueden transformar negocios. Imagina lo que podríamos crear juntos para impulsar tu empresa en el mundo digital.</p>
        <CTAButton text='Hablemos de tu Proyecto Innovador' />
      </motion.div>
      <section className="technologies-section">
        <h2>Tecnologías Utilizadas en Nuestros Proyectos</h2>
        <ul className="technologies-list">
          {Array.from(new Set(projects.flatMap(project => project.technologies))).map((tech, index) => (
            <li key={index}>{tech}</li>
          ))}
        </ul>
      </section>
      <section className="project-benefits">
        <h2>Beneficios de Nuestros Proyectos de Desarrollo</h2>
        <ul>
          <li>Soluciones personalizadas que se adaptan perfectamente a las necesidades de tu negocio</li>
          <li>Mejora de la eficiencia operativa y reducción de costes</li>
          <li>Aumento de la satisfacción del cliente y fidelización</li>
          <li>Escalabilidad para crecer con tu negocio</li>
          <li>Integración perfecta con sistemas existentes</li>
          <li>Soporte continuo y mantenimiento para garantizar el rendimiento óptimo</li>
        </ul>
      </section>
      <a href="https://wa.me/34622112337" target="_blank" rel="noopener noreferrer" className="whatsapp-float" aria-label="Contactar por WhatsApp">
        <FaWhatsapp aria-hidden="true" />
      </a>
    </div>
  );
};

export default ProjectsPage;
