import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineController, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import * as d3 from 'd3';
import '../styles/InteractiveInnovationLab.css';
import Chatbot from './utils/Chatbot';

ChartJS.register(
  CategoryScale, LinearScale, LineController, LineElement, PointElement, Title, Tooltip, Legend
);

const D3Treemap = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0) return;

    const width = svgRef.current.clientWidth;
    const height = 400;
    const margin = { top: 10, right: 10, bottom: 10, left: 10 };

    const root = d3.hierarchy({ children: data })
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);

    const treemapLayout = d3.treemap()
      .size([width, height])
      .padding(5)
      .round(true);

    treemapLayout(root);

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("font-family", "sans-serif");

    const colorScale = d3.scaleLinear()
      .domain([0, d3.max(root.leaves(), d => d.value)])
      .range(["#00c6ff", "#0072ff"]);

    const node = svg.selectAll("g")
      .data(root.leaves())
      .enter()
      .append("g")
      .attr("transform", d => `translate(${d.x0},${d.y0})`);

    node.append("rect")
      .attr("id", d => d.data.title)
      .attr("width", d => d.x1 - d.x0)
      .attr("height", d => d.y1 - d.y0)
      .attr("fill", d => colorScale(d.value))
      .attr("stroke", "#ffffff")
      .attr("stroke-width", 2)
      .style("rx", 10)
      .style("ry", 10)
      .style("box-shadow", "0 4px 10px rgba(0, 0, 0, 0.1)")
      .on("mouseover", function() {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("fill", "#ff4b2b")
          .attr("stroke", "#ff5733")
          .attr("stroke-width", 3);
      })
      .on("mouseout", function() {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("fill", d => colorScale(d.value))
          .attr("stroke", "#ffffff")
          .attr("stroke-width", 2);
      });

    node.append("text")
      .attr("x", 5)
      .attr("y", 20)
      .attr("fill", "white")
      .style("font-weight", "bold")
      .style("font-size", "12px")
      .text(d => `${d.data.title}`);

    node.append("title")
      .text(d => `Componente: ${d.data.title}\nValor: ${d.data.value}\nDescripción: Este componente es clave para...`);

  }, [data]);

  return <svg ref={svgRef} style={{width: '100%', height: '400px'}}></svg>;
};

const InteractiveInnovationLab = () => {
  const [activeTab, setActiveTab] = useState('visualizacion');
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Innovación en Tiempo Real',
        data: [],
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.4,
      },
    ],
  });

  const [treemapData] = useState([
    { title: 'Axis', value: 716 },
    { title: 'Controls', value: 573 },
    { title: 'Data', value: 1662 },
    { title: 'Flex', value: 1102 },
    { title: 'Physics', value: 557 }
  ]);

  const [sortingAlgorithm, setSortingAlgorithm] = useState({
    array: [],
    currentIndex: 0,
    sorted: false
  });

  useEffect(() => {
    // Evita el desplazamiento al cambiar de pestaña
    const labContent = document.querySelector('.lab-content');
    if (labContent) {
      labContent.scrollTop = 0;
    }
  }, [activeTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData(prevData => ({
        labels: [...prevData.labels, new Date().toLocaleTimeString()].slice(-10),
        datasets: [{
          ...prevData.datasets[0],
          data: [...prevData.datasets[0].data, Math.floor(Math.random() * 100)].slice(-10),
        }],
      }));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (activeTab === 'algoritmo') {
      setSortingAlgorithm({
        array: Array.from({ length: 20 }, () => Math.floor(Math.random() * 100)),
        currentIndex: 0,
        sorted: false
      });
    }
  }, [activeTab]);

  const bubbleSort = () => {
    const { array, currentIndex } = sortingAlgorithm;
    if (currentIndex >= array.length - 1) {
      setSortingAlgorithm(prev => ({ ...prev, sorted: true }));
      return;
    }

    const newArray = [...array];
    if (newArray[currentIndex] > newArray[currentIndex + 1]) {
      [newArray[currentIndex], newArray[currentIndex + 1]] = [newArray[currentIndex + 1], newArray[currentIndex]];
    }

    setSortingAlgorithm(prev => ({
      ...prev,
      array: newArray,
      currentIndex: (currentIndex + 1) % (array.length - 1)
    }));
  };

  useEffect(() => {
    if (activeTab === 'algoritmo' && !sortingAlgorithm.sorted) {
      const timer = setTimeout(() => {
        bubbleSort();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [sortingAlgorithm, activeTab]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Innovación en Tiempo Real',
        color: '#FFFFFF',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: { color: '#FFFFFF' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
      },
      x: {
        ticks: { color: '#FFFFFF' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
      },
    },
  };

  return (
    <div className="interactive-lab">
      <h2 className="lab-title">Laboratorio de Innovación Interactivo</h2>
      <div className="lab-tabs">
        <button
          className={`lab-tab ${activeTab === 'visualizacion' ? 'active' : ''}`}
          onClick={() => setActiveTab('visualizacion')}
        >
          Visualización de Datos
        </button>
        <button
          className={`lab-tab ${activeTab === 'treemap' ? 'active' : ''}`}
          onClick={() => setActiveTab('treemap')}
        >
          Mapa de Árbol Interactivo
        </button>
        <button
          className={`lab-tab ${activeTab === 'algoritmo' ? 'active' : ''}`}
          onClick={() => setActiveTab('algoritmo')}
        >
          Algoritmo en Tiempo Real
        </button>
        <button
          className={`lab-tab ${activeTab === 'chatbot' ? 'active' : ''}`}
          onClick={() => setActiveTab('chatbot')}
        >
          Chatbot IA
        </button>
      </div>
      <div className="lab-content">
        {activeTab === 'visualizacion' && (
          <motion.div
            className="data-visualization"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <h3>Visualización de Datos en Tiempo Real</h3>
            <div className="chart-container">
              <Line data={data} options={chartOptions} />
            </div>
          </motion.div>
        )}
        {activeTab === 'treemap' && (
          <motion.div
            className="treemap-visualization"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <h3>Mapa de Árbol Interactivo</h3>
            <div className="chart-container">
              <D3Treemap data={treemapData} />
            </div>
          </motion.div>
        )}
        {activeTab === 'algoritmo' && (
          <motion.div
            className="algorithm-demo"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <h3>Demostración de Algoritmo de Ordenamiento</h3>
            <div className="algorithm-container">
              {sortingAlgorithm.array.map((value, index) => (
                <div
                  key={index}
                  className={`bar ${index === sortingAlgorithm.currentIndex || index === sortingAlgorithm.currentIndex + 1 ? 'active' : ''}`}
                  style={{ height: `${value * 3}px` }}
                ></div>
              ))}
            </div>
          </motion.div>
        )}
        {activeTab === 'chatbot' && (
          <motion.div
            className="ai-chatbot"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <h3>Chatbot con IA</h3>
            <Chatbot />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default InteractiveInnovationLab;
