import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../css/chtbt.css';

const ChatbotDemo = () => {
  const [messages, setMessages] = useState([
    { text: "¡Hola! Soy el asistente virtual de TechSupport. ¿En qué puedo ayudarte hoy?", sender: 'bot' }
  ]);
  const [input, setInput] = useState('');

  const botResponses = {
    "problema": "Lamento escuchar que tienes un problema. ¿Podrías proporcionar más detalles sobre el problema que estás experimentando?",
    "factura": "Para consultas sobre facturación, te recomiendo visitar nuestra sección de 'Mi Cuenta' en el sitio web. Allí podrás ver tus facturas y realizar pagos. ¿Hay algo específico que necesites saber sobre tu factura?",
    "producto": "Tenemos una amplia gama de productos. ¿Hay alguna categoría específica que te interese? Puedo proporcionarte información detallada sobre cualquiera de nuestros productos.",
    "default": "Gracias por tu pregunta. Para poder ayudarte mejor, ¿podrías proporcionar más detalles o especificar en qué área necesitas asistencia?"
  };

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      
      setTimeout(() => {
        const botReply = botResponses[input.toLowerCase().split(' ').find(word => botResponses[word])] || botResponses["default"];
        setMessages(msgs => [...msgs, { text: botReply, sender: 'bot' }]);
      }, 1000);
      
      setInput('');
    }
  };

  useEffect(() => {
    const chatContainer = document.querySelector('.demo-chat-messages');
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [messages]);

  return (
    <div className="chatbot-demo">
      <div className="chat-header">
        <h3>TechSupport Assistant</h3>
      </div>
      <div className="demo-chat-messages">
        {messages.map((msg, idx) => (
          <motion.div 
            key={idx} 
            className={`message ${msg.sender}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {msg.text}
          </motion.div>
        ))}
      </div>
      <div className="demo-chat-input">
        <input 
          value={input} 
          onChange={(e) => setInput(e.target.value)}
          placeholder="Escribe tu mensaje aquí..."
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        />
        <motion.button 
          onClick={handleSend}
          whileTap={{ scale: 0.95 }}
        >
          Enviar
        </motion.button>
      </div>
    </div>
  );
};

export default ChatbotDemo;