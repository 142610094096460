import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CookieConsent.css';

const CookieConsent = ({ initialPreferences, onSave }) => {
  const [visible, setVisible] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState(initialPreferences || {
    necessary: true,
    functional: false,
    performance: false,
    advertising: false
  });

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setVisible(true);
    }
  }, []);

  const handlePreferenceChange = (e) => {
    setCookiePreferences({
      ...cookiePreferences,
      [e.target.name]: e.target.checked
    });
  };

  const savePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(cookiePreferences));
    onSave(cookiePreferences);
    setVisible(false);
  };

  const acceptAll = () => {
    const allAccepted = {
      necessary: true,
      functional: true,
      performance: true,
      advertising: true
    };
    localStorage.setItem('cookieConsent', JSON.stringify(allAccepted));
    onSave(allAccepted);
    setVisible(false);
  };

  const declineAll = () => {
    const allDeclined = {
      necessary: true,
      functional: false,
      performance: false,
      advertising: false
    };
    localStorage.setItem('cookieConsent', JSON.stringify(allDeclined));
    onSave(allDeclined);
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="cookie-consent">
      <div className="cookie-consent-content">
        <h2>Política de Cookies y Tratamiento de Datos</h2>
        <p>
          Utilizamos cookies para mejorar su experiencia de navegación, servir anuncios o contenido personalizado y analizar nuestro tráfico. Pulse el botón "Aceptar todas" para consentir el uso de todas las cookies. También puede personalizar sus preferencias haciendo clic en "Personalizar".
        </p>
        <div className="cookie-preferences">
          <label>
            <input 
              type="checkbox" 
              name="necessary" 
              checked={cookiePreferences.necessary} 
              onChange={handlePreferenceChange}
              disabled
            /> 
            Necesarias (siempre activas)
          </label>
          <label>
            <input 
              type="checkbox" 
              name="functional" 
              checked={cookiePreferences.functional} 
              onChange={handlePreferenceChange}
            /> 
            Funcionales
          </label>
          <label>
            <input 
              type="checkbox" 
              name="performance" 
              checked={cookiePreferences.performance} 
              onChange={handlePreferenceChange}
            /> 
            Rendimiento
          </label>
          <label>
            <input 
              type="checkbox" 
              name="advertising" 
              checked={cookiePreferences.advertising} 
              onChange={handlePreferenceChange}
            /> 
            Publicidad
          </label>
        </div>
        <div className="cookie-consent-actions">
          <button onClick={acceptAll} className="accept-button">Aceptar todas</button>
          <button onClick={savePreferences} className="save-button">Guardar preferencias</button>
          <button onClick={declineAll} className="decline-button">Rechazar no esenciales</button>
          <Link to="/politica-privacidad" className="privacy-link">Leer más</Link>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;