import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FaRocket, FaLightbulb, FaCogs, FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import InteractiveInnovationLab from './InteractiveInnovationLab';
import '../styles/HomePage.css';

const CTAButton = ({ text = "Contactar" }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contacto');
  };

  return (
    <motion.button
      className="homepage-cta-button"
      onClick={handleClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {text}
    </motion.button>
  );
};

const CodeAnimation = () => {
  const [text, setText] = React.useState('');
  const fullText = `function innovate() {
  const ideas = generateIdeas();
  const solution = implementSolution(ideas);
  return transformBusiness(solution);
}`;

  React.useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < fullText.length) {
        setText(fullText.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 50);

    return () => clearInterval(typingInterval);
  }, [fullText]);

  return (
    <motion.div
      className="code-animation"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <pre>
        <code>{text}</code>
      </pre>
    </motion.div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: "¿Cómo crear una página web profesional?",
      answer: "En InnovativeCode ofrecemos soluciones personalizadas que incluyen diseño UX/UI, desarrollo a medida y optimización SEO para que tu página web destaque en Internet.",
    },
    {
      question: "¿Qué tecnologías usamos para desarrollar apps?",
      answer: "Utilizamos tecnologías modernas como React Native, Flutter, y plataformas nativas para crear aplicaciones móviles rápidas y escalables.",
    },
    {
      question: "¿Cómo mejoramos el rendimiento de mi web existente?",
      answer: "Optimizamos la velocidad de carga, mejoramos el diseño y añadimos nuevas funcionalidades como chatbots e integraciones avanzadas para maximizar la experiencia del usuario.",
    },
    {
      question: "¿Qué es la transformación digital?",
      answer: "Es el proceso de integrar tecnología digital en todas las áreas de tu negocio para mejorar operaciones y ofrecer mayor valor a tus clientes.",
    },
  ];

  return (
    <section className="faq-section">
      <h2>Preguntas Frecuentes</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

const HomePage = () => {
  const [activeSection, setActiveSection] = React.useState(0);
  const controls = useAnimation();

  React.useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.1 }
    }));
  }, [controls]);

  const sections = [
    {
      title: "Desarrollo Web Innovador en Madrid",
      description: "Creamos sitios web personalizados de alto rendimiento con diseños modernos, optimización SEO y enfoque en la experiencia del usuario.",
      icon: <FaRocket />,
      color: "#4a90e2",
    },
    {
      title: "Aplicaciones Móviles Revolucionarias",
      description: "Desarrollamos apps nativas y multiplataforma para conectar con tus clientes y transformar tu modelo de negocio.",
      icon: <FaLightbulb />,
      color: "#50e3c2",
    },
    {
      title: "SEO y Marketing Digital Avanzado",
      description: "Implementamos estrategias SEO avanzadas y marketing digital efectivo para maximizar tu visibilidad online.",
      icon: <FaCogs />,
      color: "#ff6b6b",
    }
  ];

  return (
    <div className="homepage">
      <Helmet>
        <title>InnovativeCode | Desarrollo Web, Apps y SEO en Madrid - Líderes en Transformación Digital</title>
        <meta
          name="description"
          content="Desarrollo web profesional, aplicaciones móviles avanzadas y soluciones tecnológicas innovadoras en Madrid. ¡Impulsa tu negocio con InnovativeCode!"
        />
        <link rel="canonical" href="https://www.innovativecode.es" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "InnovativeCode",
            url: "https://www.innovativecode.es",
            description:
              "Desarrollo web profesional, aplicaciones móviles avanzadas y soluciones tecnológicas en Madrid.",
            potentialAction: {
              "@type": "SearchAction",
              target: "https://www.innovativecode.es/?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
      </Helmet>

      <section className="hero">
        <motion.div
          className="hero-content"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1>InnovativeCode: Tu Socio en Desarrollo Digital en Madrid</h1>
          <p>Transformamos tu negocio con soluciones digitales innovadoras.</p>
          <CTAButton text="Inicia Tu Transformación Digital" />
        </motion.div>
        <div className="hero-visual">
          <CodeAnimation />
        </div>
      </section>

      <section className="innovation-showcase">
        <h2>Nuestros Servicios de Innovación Digital</h2>
        <div className="showcase-container">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              className={`showcase-item ${activeSection === index ? "active" : ""}`}
              onClick={() => setActiveSection(index)}
              whileHover={{ scale: 1.05 }}
              style={{ backgroundColor: section.color }}
            >
              <div className="showcase-icon">{section.icon}</div>
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="tech-stack">
        <h2>Tecnologías de Vanguardia para Soluciones Innovadoras</h2>
        <div className="tech-grid">
          {['React', 'Node.js', 'Python', 'AWS', 'Docker', 'GraphQL', 'TensorFlow', 'Blockchain'].map((tech, index) => (
            <motion.div
              key={index}
              className="tech-item"
              custom={index}
              initial={{ opacity: 0, y: 50 }}
              animate={controls}
            >
              {tech}
            </motion.div>
          ))}
        </div>
      </section>

      <FAQSection />

      <section className="interactive-demo">
        <h2>Experimenta el Potencial de Nuestras Soluciones Digitales</h2>
        <div className="demo-container">
          <InteractiveInnovationLab />
        </div>
      </section>

      <section className="future-vision">
        <h2>Construyendo el Futuro Digital de tu Negocio en Madrid</h2>
        <div className="vision-content">
          <p>InnovativeCode transforma ideas en realidades digitales. Lidera la innovación en tu sector con nuestras soluciones tecnológicas avanzadas.</p>
        </div>
        <CTAButton text="Inicia Tu Transformación Digital" />
      </section>

      <a
        href="https://wa.me/34622112337"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-float"
        aria-label="Contactar por WhatsApp"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};

export default HomePage;
