import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/Homepage';
import Servicios from './components/Services';
import Proyectos from './components/ProjectShowcase';
import Nosotros from './components/About';
import Contacto from './components/Contact';
import ScrollToTop from './components/utils/ScrollToTop';
import CookieConsent from './components/cookies-privacy/CookieConsent';
import { useCookies } from './components/cookies-privacy/useCookies';
import PrivacyPolicy from './components/cookies-privacy/PrivacyPolicy';
import './App.css';

function App() {
  const [cookies, updateCookiePreferences] = useCookies();
  const [showCookieSettings, setShowCookieSettings] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setShowCookieBanner(true);
    }
  }, []);

  const handleSaveCookiePreferences = (newPreferences) => {
    updateCookiePreferences(newPreferences);
    localStorage.setItem('cookieConsent', JSON.stringify(newPreferences));
    setShowCookieSettings(false);
    setShowCookieBanner(false);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<HomePage cookies={cookies} />} />
            <Route path="/Inicio" element={<HomePage cookies={cookies} />} />
            <Route path="/Servicios" element={<Servicios cookies={cookies} />} />
            <Route path="/Proyectos" element={<Proyectos cookies={cookies} />} />
            <Route path="/Nosotros" element={<Nosotros cookies={cookies} />} />
            <Route path="/Contacto" element={<Contacto cookies={cookies} />} />
            <Route path="/Privacidad" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer>
          {/* Otros elementos del footer */}
          <button onClick={() => setShowCookieSettings(true)}>Configuración de Cookies</button>
        </Footer>
        {(showCookieSettings || showCookieBanner) && (
          <CookieConsent 
            initialPreferences={cookies}
            onSave={handleSaveCookiePreferences}
          />
        )}
      </div>
    </Router>
  );
}

export default App;