import React, { useState } from 'react';
import { motion } from 'framer-motion';
import '../css/ecmia.css';

const EcommerceDemo = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [cart, setCart] = useState([]);

  const products = [
    { id: 1, name: 'Smartphone X', category: 'electronics', price: 699, image: 'https://via.placeholder.com/150?text=Smartphone' },
    { id: 2, name: 'Laptop Pro', category: 'electronics', price: 1299, image: 'https://via.placeholder.com/150?text=Laptop' },
    { id: 3, name: 'Running Shoes', category: 'sports', price: 89, image: 'https://via.placeholder.com/150?text=Shoes' },
    { id: 4, name: 'Coffee Maker', category: 'home', price: 49, image: 'https://via.placeholder.com/150?text=Coffee+Maker' },
    { id: 5, name: 'Wireless Headphones', category: 'electronics', price: 199, image: 'https://via.placeholder.com/150?text=Headphones' },
    { id: 6, name: 'Fitness Tracker', category: 'sports', price: 79, image: 'https://via.placeholder.com/150?text=Fitness+Tracker' },
  ];

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  const filteredProducts = activeCategory === 'all' 
    ? products 
    : products.filter(product => product.category === activeCategory);

  const aiRecommendations = [
    { id: 7, name: 'Smart Watch', price: 249, image: 'https://via.placeholder.com/150?text=Smart+Watch' },
    { id: 8, name: 'Bluetooth Speaker', price: 89, image: 'https://via.placeholder.com/150?text=Speaker' },
  ];

  return (
    <div className="ecommerce-demo">
      <div className="store-header">
        <h3>TechShop AI</h3>
        <div className="cart-info">
          🛒 {cart.length} items
        </div>
      </div>
      <div className="category-filter">
        {['all', 'electronics', 'sports', 'home'].map(category => (
          <button 
            key={category}
            className={activeCategory === category ? 'active' : ''}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="product-grid">
        {filteredProducts.map(product => (
          <motion.div 
            key={product.id} 
            className="product-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={product.image} alt={product.name} />
            <h4>{product.name}</h4>
            <p>${product.price}</p>
            <motion.button 
              onClick={() => addToCart(product)}
              whileTap={{ scale: 0.95 }}
            >
              Add to Cart
            </motion.button>
          </motion.div>
        ))}
      </div>
      <div className="ai-recommendations">
        <h4>Recomendado para ti</h4>
        <div className="recommendation-list">
          {aiRecommendations.map(product => (
            <motion.div 
              key={product.id} 
              className="product-card"
              whileHover={{ scale: 1.05 }}
            >
              <img src={product.image} alt={product.name} />
              <h4>{product.name}</h4>
              <p>${product.price}</p>
              <motion.button 
                onClick={() => addToCart(product)}
                whileTap={{ scale: 0.95 }}
              >
                Add to Cart
              </motion.button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EcommerceDemo;