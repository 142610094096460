import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaTasks, FaRobot, FaChartLine } from 'react-icons/fa';
import '../css/AIProjectManagementDemo.css';

const AIProjectManagementDemo = () => {
  const [tasks, setTasks] = useState([
    { id: 1, title: 'Diseño de UI', status: 'En progreso', aiSuggestion: 'Considerar principios de diseño accesible' },
    { id: 2, title: 'Desarrollo Backend', status: 'Pendiente', aiSuggestion: 'Utilizar arquitectura de microservicios' },
    { id: 3, title: 'Testing', status: 'Completado', aiSuggestion: 'Aumentar cobertura de pruebas unitarias' },
  ]);

  const [newTask, setNewTask] = useState('');

  const addTask = () => {
    if (newTask.trim() !== '') {
      const aiSuggestions = [
        'Considerar la escalabilidad',
        'Implementar CI/CD',
        'Optimizar para dispositivos móviles',
        'Realizar pruebas de seguridad',
      ];
      const randomSuggestion = aiSuggestions[Math.floor(Math.random() * aiSuggestions.length)];
      
      setTasks([...tasks, {
        id: tasks.length + 1,
        title: newTask,
        status: 'Pendiente',
        aiSuggestion: randomSuggestion,
      }]);
      setNewTask('');
    }
  };

  return (
    <div className="ai-project-management">
      <h2>Plataforma de Gestión de Proyectos con IA</h2>
      <div className="add-task">
        <input
          type="text"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          placeholder="Nueva tarea"
        />
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={addTask}
        >
          Añadir Tarea
        </motion.button>
      </div>
      <div className="task-list">
        {tasks.map((task) => (
          <motion.div
            key={task.id}
            className="task-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h3>{task.title}</h3>
            <p>Estado: {task.status}</p>
            <div className="ai-suggestion">
              <FaRobot />
              <p>{task.aiSuggestion}</p>
            </div>
          </motion.div>
        ))}
      </div>
      <div className="project-stats">
        <div className="stat">
          <FaTasks />
          <p>Total de tareas: {tasks.length}</p>
        </div>
        <div className="stat">
          <FaChartLine />
          <p>Progreso del proyecto: {Math.round((tasks.filter(t => t.status === 'Completado').length / tasks.length) * 100)}%</p>
        </div>
      </div>
    </div>
  );
};

export default AIProjectManagementDemo;