import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FaRocket, FaLightbulb, FaHandshake, FaWhatsapp, FaChartLine } from 'react-icons/fa';
import '../styles/AboutUs.css';
import { useNavigate } from 'react-router-dom';

const CTAButton = ({ text = "Contactar" }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contacto');
  };

  return (
    <motion.button
      className="cta-button"
      onClick={handleClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {text}
    </motion.button>
  );
};

const AboutUs = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "InnovativeCode",
    "description": "Empresa líder en desarrollo web, aplicaciones móviles y soluciones digitales innovadoras en Madrid. Transformamos negocios con tecnología de vanguardia.",
    "url": "https://www.innovativecode.es",
    "logo": "https://www.innovativecode.es/logo.png",
    "foundingDate": "2020",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Calle Dublin 39 BLOQUE I",
      "addressLocality": "Las Rozas",
      "addressRegion": "Madrid",
      "postalCode": "28232",
      "addressCountry": "ES"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+34-622-112-337",
      "contactType": "customer service",
      "email": "info@innovativecode.es"
    },
    "sameAs": [
      "https://www.facebook.com/InnovativeCode",
      "https://www.twitter.com/InnovativeCode",
      "https://www.linkedin.com/company/innovativecode",
      "https://www.instagram.com/innovativecode"
    ]
  };

  return (
    <div className="about-us-container">
      <Helmet>
        <title>Sobre InnovativeCode | Empresa de Desarrollo Web y Apps en Madrid</title>
        <meta name="description" content="Descubre InnovativeCode, tu socio en desarrollo web y aplicaciones móviles en Madrid. Impulsamos negocios con soluciones tecnológicas innovadoras y personalizadas." />
        <meta name="keywords" content="desarrollo web Madrid, aplicaciones móviles, innovación tecnológica, empresa de software, transformación digital, SEO" />
        <link rel="canonical" href="https://www.innovativecode.es/nosotros" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <motion.h1 {...fadeIn}>InnovativeCode: Impulsando la Innovación Digital en Madrid</motion.h1>
      
      <motion.section className="vision-section" {...fadeIn}>
        <h2>Nuestra Visión</h2>
        <p>En InnovativeCode, transformamos ideas en soluciones digitales personalizadas que ayudan a las empresas a prosperar en la era digital. Nos impulsa la pasión por la innovación tecnológica y la excelencia en cada proyecto.</p>
      </motion.section>

      <motion.section className="values-section" {...fadeIn}>
        <h2>Nuestros Valores</h2>
        <div className="values-grid">
          <div className="value-item">
            <FaRocket className="value-icon" />
            <h3>Innovación</h3>
            <p>Impulsamos ideas creativas que redefinen lo posible en cada solución tecnológica.</p>
          </div>
          <div className="value-item">
            <FaLightbulb className="value-icon" />
            <h3>Creatividad</h3>
            <p>Diseñamos soluciones únicas que destacan en el mercado digital y aportan valor real a las empresas.</p>
          </div>
          <div className="value-item">
            <FaHandshake className="value-icon" />
            <h3>Colaboración</h3>
            <p>Trabajamos estrechamente con nuestros socios para garantizar el éxito de cada proyecto.</p>
          </div>
          <div className="value-item">
            <FaChartLine className="value-icon" />
            <h3>Excelencia</h3>
            <p>Superamos las expectativas con soluciones tecnológicas de alto impacto y calidad.</p>
          </div>
        </div>
      </motion.section>

      <motion.section className="approach-section" {...fadeIn}>
        <h2>Nuestro Enfoque</h2>
        <p>Abordamos cada proyecto con un enfoque estratégico, centrado en:</p>
        <ul>
          <li>Diseño personalizado y centrado en el usuario</li>
          <li>Uso de tecnologías avanzadas para soluciones escalables</li>
          <li>Integración de estrategias de SEO para maximizar la visibilidad online</li>
          <li>Optimización de rendimiento para garantizar experiencias excepcionales</li>
          <li>Iteraciones ágiles y feedback constante</li>
        </ul>
      </motion.section>

      <motion.section className="cta-section" {...fadeIn}>
        <h2>Impulsa tu Futuro Digital con InnovativeCode</h2>
        <p>Prepárate para llevar tu empresa al siguiente nivel con soluciones digitales innovadoras. En InnovativeCode, creamos tecnologías que marcan la diferencia.</p>
        <CTAButton text='Inicia Tu Transformación Digital' />
      </motion.section>

      <section className="tech-stack-section">
        <h2>Nuestras Tecnologías</h2>
        <ul className="tech-list">
          <li>React</li>
          <li>Node.js</li>
          <li>Python</li>
          <li>GraphQL</li>
          <li>Docker</li>
          <li>TensorFlow</li>
          <li>Flutter</li>
        </ul>
      </section>

      <a href="https://wa.me/34622112337" target="_blank" rel="noopener noreferrer" className="whatsapp-float" aria-label="Contactar por WhatsApp">
        <FaWhatsapp aria-hidden="true" />
      </a>
    </div>
  );
};

export default AboutUs;
