import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FaRocket, FaEnvelope, FaPhoneAlt, FaMapMarkerAlt, FaWhatsapp, FaLinkedin, FaTwitter, FaFacebookSquare } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import '../styles/contact.css';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_mail: '',
    phone_number: '',
    enterprise: '',
    type_project: 'web',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const controls = useAnimation();

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 }
    }));
    emailjs.init('MfwJFCI-UnaaAnCB_');
  }, [controls]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const result = await emailjs.send(
        'service_fds484p',
        'template_da6o44p',
        formData,
        'MfwJFCI-UnaaAnCB_'
      );

      if (result.text === 'OK') {
        setIsSubmitted(true);
        setFormData({
          from_name: '',
          from_mail: '',
          phone_number: '',
          enterprise: '',
          type_project: 'web',
          message: ''
        });
      } else {
        throw new Error('Error al enviar el email');
      }
    } catch (error) {
      console.error('Error al enviar el email:', error);
      setError('Hubo un problema al enviar tu mensaje. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const contactInfo = [
    { icon: <FaEnvelope />, text: 'info@innovativecode.es', label: 'Email' },
    { icon: <FaPhoneAlt />, text: '+34 622 112 337', label: 'Teléfono' },
    { icon: <FaMapMarkerAlt />, text: 'Calle Dublin 39 BLOQUE I. 28232, Las Rozas, Madrid. España', label: 'Dirección' }
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "InnovativeCode",
    "url": "https://www.innovativecode.es",
    "logo": "https://www.innovativecode.es/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+34-622-112-337",
      "contactType": "customer service",
      "email": "info@innovativecode.es",
      "areaServed": "ES",
      "availableLanguage": ["Spanish", "English"]
    },
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Calle Dublin 39 BLOQUE I",
      "addressLocality": "Las Rozas",
      "addressRegion": "Madrid",
      "postalCode": "28232",
      "addressCountry": "ES"
    },
    "sameAs": [
      "https://www.facebook.com/InnovativeCode",
      "https://www.twitter.com/InnovativeCode",
      "https://www.linkedin.com/company/innovativecode"
    ]
  };

  return (
    <section className="contact-section">
      <Helmet>
        <title>Contacta con InnovativeCode | Desarrollo Web y Apps en Madrid</title>
        <meta name="description" content="¿Necesitas una solución digital innovadora? Contacta con InnovativeCode, tu socio en desarrollo web y aplicaciones móviles en Madrid. Transforma tu negocio hoy." />
        <meta name="keywords" content="contacto, desarrollo web Madrid, aplicaciones móviles, consultoría tecnológica, presupuesto desarrollo web, InnovativeCode" />
        <link rel="canonical" href="https://www.innovativecode.es/contacto" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <h1>Contacta con InnovativeCode: Tu Socio en Desarrollo Digital en Madrid</h1>
      <p className="contact-intro">¿Listo para llevar tu negocio al siguiente nivel tecnológico? Estamos aquí para convertir tus ideas en soluciones digitales innovadoras. Contáctanos hoy y comencemos a construir el futuro de tu empresa.</p>
      
      <div className="contact-container">
        <motion.div 
          className="contact-form"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2>Inicia Tu Proyecto Innovador</h2>
          {!isSubmitted ? (
            <form onSubmit={handleSubmit}>
              <motion.input
                type="text"
                name="from_name"
                placeholder="Tu nombre"
                required
                value={formData.from_name}
                onChange={handleInputChange}
                custom={0}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
              />
              <motion.input
                type="email"
                name="from_mail"
                placeholder="Tu email"
                required
                value={formData.from_mail}
                onChange={handleInputChange}
                custom={1}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
              />
              <motion.input
                type="tel"
                name="phone_number"
                placeholder="Tu teléfono"
                required
                value={formData.phone_number}
                onChange={handleInputChange}
                custom={2}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
              />
              <motion.input
                type="text"
                name="enterprise"
                placeholder="Nombre de tu empresa"
                value={formData.enterprise}
                onChange={handleInputChange}
                custom={3}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
              />
              <motion.select
                name="type_project"
                value={formData.type_project}
                onChange={handleInputChange}
                custom={4}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
              >
                <option value="web">Desarrollo Web</option>
                <option value="mobile">Aplicación Móvil</option>
                <option value="ai">Inteligencia Artificial</option>
                <option value="seo">SEO y Marketing Digital</option>
                <option value="other">Otro Proyecto</option>
              </motion.select>
              <motion.textarea
                name="message"
                placeholder="Cuéntanos sobre tu proyecto o necesidades digitales"
                required
                value={formData.message}
                onChange={handleInputChange}
                custom={5}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
              />
              <motion.button
                type="submit"
                className="submit-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                custom={6}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Enviando...' : 'Iniciar Mi Proyecto Digital'} <FaRocket />
              </motion.button>
              {error && <p className="error-message">{error}</p>}
            </form>
          ) : (
            <motion.div
              className="success-message"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h3>¡Gracias por contactarnos!</h3>
              <p>Hemos recibido tu mensaje. Nuestro equipo de expertos en desarrollo web y aplicaciones móviles se pondrá en contacto contigo pronto para comenzar a revolucionar tu negocio digital.</p>
            </motion.div>
          )}
        </motion.div>
        <motion.div
          className="contact-info"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h2>Conecta con InnovativeCode</h2>
          {contactInfo.map((info, index) => (
            <motion.div
              key={index}
              className="info-item"
              custom={index}
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
            >
              {info.icon}
              <span><strong>{info.label}:</strong> {info.text}</span>
            </motion.div>
          ))}
          <motion.div
            className="social-media"
            custom={contactInfo.length}
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
          >
            <h3>Síguenos en Redes Sociales</h3>
            <div className="social-icons">
              <a href="https://www.linkedin.com/company/innovativecode" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FaLinkedin /></a>
              <a href="https://www.twitter.com/InnovativeCode" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><FaTwitter /></a>
              <a href="https://www.facebook.com/InnovativeCode" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebookSquare /></a>
            </div>
          </motion.div>
          <motion.div
            className="innovation-promise"
            custom={contactInfo.length + 1}
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
          >
            <h3>Nuestra Promesa de Innovación Digital</h3>
            <p>En InnovativeCode, no solo desarrollamos soluciones web y móviles; creamos experiencias digitales que transforman negocios en Madrid y toda España. Cada proyecto es una oportunidad para redefinir los límites de lo posible en el mundo digital.</p>
          </motion.div>
        </motion.div>
      </div>
      <section className="faq-section">
        <h2>Preguntas Frecuentes</h2>
        <div className="faq-grid">
          <div className="faq-item">
            <h3>¿Cuánto cuesta desarrollar una aplicación web o móvil?</h3>
            <p>El costo varía según la complejidad del proyecto. Ofrecemos presupuestos personalizados después de entender tus necesidades específicas.</p>
          </div>
          <div className="faq-item">
            <h3>¿Cuánto tiempo toma desarrollar un proyecto?</h3>
            <p>Los tiempos de desarrollo dependen del alcance del proyecto. Trabajamos de manera ágil para entregar resultados eficientemente.</p>
          </div>
          <div className="faq-item">
            <h3>¿Ofrecen servicios de mantenimiento post-lanzamiento?</h3>
            <p>Sí, ofrecemos servicios de mantenimiento y soporte continuo para asegurar que tu solución digital siga funcionando óptimamente.</p>
          </div>
        </div>
      </section>
      <a href="https://wa.me/34622112337" target="_blank" rel="noopener noreferrer" className="whatsapp-float" aria-label="Contactar por WhatsApp">
        <FaWhatsapp aria-hidden="true" />
      </a>
    </section>
  );
};

export default ContactSection;