import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaGithub, FaRocket } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>InnovativeCode</h3>
          <p>Explorando el universo digital, una línea de código a la vez.</p>
        </div>
        <div className="footer-section">
          <h3>Enlaces Rápidos</h3>
          <ul>
            <li><Link to="/">Inicio</Link></li>
            <li><Link to="/servicios">Servicios</Link></li>
            <li><Link to="/proyectos">Proyectos</Link></li>
            <li><Link to="/Nosotros">Sobre Nosotros</Link></li>
            <li><Link to="/contacto">Contacto</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contáctanos</h3>
          <p>Email: info@innovativecode.es</p>
          <p>Teléfono: +34 622112337</p>
          <p>Dirección: Calle Dublin 39 BLOQUE I. 28232, Las Rozas, Madrid. España</p>
        </div>
        <div className="footer-section">
          <h3>Conoce mas de nosotros</h3>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 InnovativeCode. Todos los derechos reservados.</p>
        <button className="back-to-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <FaRocket />
        </button>
      </div>
    </footer>
  );
};

export default Footer;