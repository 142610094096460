import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy">
        <h1>Política de Privacidad de InnovativeCode</h1>
        <p>Última actualización: 14 de Septiembre de 2024</p>
        
        <section>
          <h2>1. Introducción</h2>
          <p>En InnovativeCode, respetamos su privacidad y nos comprometemos a proteger sus datos personales. Esta política de privacidad le informará sobre cómo cuidamos sus datos personales cuando visita nuestro sitio web y le informará sobre sus derechos de privacidad y cómo la ley le protege.</p>
        </section>
        
        <section>
          <h2>2. Los datos que recopilamos sobre usted</h2>
          <p>Podemos recopilar, usar, almacenar y transferir diferentes tipos de datos personales sobre usted, que hemos agrupado de la siguiente manera:</p>
          <ul>
            <li>Datos de identidad: Nombre, apellido, nombre de usuario o identificador similar.</li>
            <li>Datos de contacto: Dirección de correo electrónico y números de teléfono.</li>
            <li>Datos técnicos: Dirección IP, datos de inicio de sesión, tipo y versión del navegador, configuración de zona horaria y ubicación, tipos y versiones de plug-ins del navegador, sistema operativo y plataforma, y otra tecnología en los dispositivos que utiliza para acceder a este sitio web.</li>
            <li>Datos de perfil: Su nombre de usuario y contraseña, sus intereses, preferencias, comentarios y respuestas a encuestas.</li>
            <li>Datos de uso: Información sobre cómo utiliza nuestro sitio web, productos y servicios.</li>
          </ul>
        </section>
        
        <section>
          <h2>3. Cómo utilizamos sus datos personales</h2>
          <p>Utilizaremos sus datos personales solo cuando la ley nos lo permita. Más comúnmente, utilizaremos sus datos personales en las siguientes circunstancias:</p>
          <ul>
            <li>Cuando necesitemos ejecutar el contrato que estamos a punto de celebrar o hemos celebrado con usted.</li>
            <li>Cuando sea necesario para nuestros intereses legítimos (o los de un tercero) y sus intereses y derechos fundamentales no se sobrepongan a esos intereses.</li>
            <li>Cuando necesitemos cumplir con una obligación legal o regulatoria.</li>
          </ul>
        </section>
        
        <section>
          <h2>4. Divulgación de sus datos personales</h2>
          <p>Podemos compartir sus datos personales con las partes establecidas a continuación para los fines establecidos en esta política de privacidad:</p>
          <ul>
            <li>Proveedores de servicios que proporcionan servicios de TI y administración de sistemas.</li>
            <li>Asesores profesionales, incluyendo abogados, banqueros, auditores y aseguradores.</li>
            <li>Autoridades fiscales, reguladoras y otras autoridades.</li>
          </ul>
        </section>
        
        <section>
          <h2>5. Seguridad de datos</h2>
          <p>Hemos implementado medidas de seguridad apropiadas para evitar que sus datos personales se pierdan, utilicen o accedan de forma no autorizada, se modifiquen o divulguen accidentalmente.</p>
        </section>
        
        <section>
          <h2>6. Sus derechos legales</h2>
          <p>Bajo ciertas circunstancias, usted tiene derechos bajo las leyes de protección de datos en relación con sus datos personales, incluyendo el derecho a:</p>
          <ul>
            <li>Solicitar acceso a sus datos personales.</li>
            <li>Solicitar la corrección de sus datos personales.</li>
            <li>Solicitar la eliminación de sus datos personales.</li>
            <li>Oponerse al procesamiento de sus datos personales.</li>
            <li>Solicitar la restricción del procesamiento de sus datos personales.</li>
            <li>Solicitar la transferencia de sus datos personales.</li>
            <li>Retirar el consentimiento en cualquier momento.</li>
          </ul>
        </section>
        
        <section>
          <h2>7. Cambios a esta política de privacidad</h2>
          <p>Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. La nueva política de privacidad se publicará en esta página.</p>
        </section>
        
        <section>
          <h2>8. Cómo contactarnos</h2>
          <p>Si tiene alguna pregunta sobre esta política de privacidad o nuestras prácticas de privacidad, contáctenos en:</p>
          <p>Email: info@innovativecode.com</p>
          <p>Dirección: Calle Dublin 39 BLOQUE I. 28232, Las Rozas, Madrid. España</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;