import { useState, useEffect } from 'react';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const useCookies = () => {
  const [cookies, setCookies] = useState(null);

  useEffect(() => {
    const cookieConsent = getCookie('cookieConsent');
    if (cookieConsent) {
      setCookies(JSON.parse(cookieConsent));
    }
  }, []);

  const updateCookiePreferences = (newPreferences) => {
    document.cookie = `cookieConsent=${JSON.stringify(newPreferences)}; path=/; max-age=31536000`;
    setCookies(newPreferences);
  };

  return [cookies, updateCookiePreferences];
};