import React, { useState } from 'react';
import { motion } from 'framer-motion';
import '../css/lrnpg.css';

const LearningPlatformDemo = () => {
  const [activeLesson, setActiveLesson] = useState(0);
  const [progress, setProgress] = useState(0);

  const lessons = [
    { title: 'Introducción a React', duration: '10:00' },
    { title: 'Componentes y Props', duration: '15:30' },
    { title: 'Estado y Ciclo de Vida', duration: '20:15' },
    { title: 'Manejo de Eventos', duration: '12:45' },
  ];

  const incrementProgress = () => {
    setProgress(prev => Math.min(prev + 25, 100));
  };

  return (
    <div className="learning-platform-demo">
      <div className="platform-header">
        <h3>TechLearn Pro</h3>
        <div className="user-info">
          <img src="https://placekitten.com/100/100" alt="User Avatar" />
          <span>John Doe</span>
        </div>
      </div>
      <div className="platform-content">
        <div className="lesson-list">
          <h4>Curso: React Fundamentals</h4>
          {lessons.map((lesson, index) => (
            <motion.div 
              key={index}
              className={`lesson-item ${activeLesson === index ? 'active' : ''}`}
              onClick={() => setActiveLesson(index)}
              whileHover={{ scale: 1.05 }}
            >
              <span>{lesson.title}</span>
              <span>{lesson.duration}</span>
            </motion.div>
          ))}
        </div>
        <div className="lesson-content">
          <video controls poster="https://via.placeholder.com/640x360.png?text=Video+Thumbnail">
            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h4>{lessons[activeLesson].title}</h4>
          <p>En esta lección, aprenderemos los conceptos fundamentales de React y cómo aplicarlos en el desarrollo de aplicaciones web modernas.</p>
          <motion.button 
            whileTap={{ scale: 0.95 }}
            onClick={incrementProgress}
          >
            Marcar como completado
          </motion.button>
        </div>
      </div>
      <div className="platform-footer">
        <div className="progress-bar">
          <div className="progress" style={{width: `${progress}%`}}></div>
        </div>
        <span>Progreso del curso: {progress}%</span>
      </div>
    </div>
  );
};

export default LearningPlatformDemo;