import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './chatbot.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([
    { text: "¡Hola! Soy el asistente virtual de InnovativeCode. ¿En qué puedo ayudarte hoy?", sender: 'bot' }
  ]);
  const [input, setInput] = useState('');

  const predefinedQuestions = [
    "¿Qué servicios ofrecen?",
    "¿Cómo pueden ayudar a mi negocio?",
    "¿Cuál es su enfoque en innovación?"
  ];

  const botResponses = {
    "¿Qué servicios ofrecen?": "En InnovativeCode, ofrecemos una gama completa de servicios digitales, incluyendo desarrollo web y móvil de vanguardia, soluciones de IA personalizadas, y consultoría en transformación digital. Nuestro objetivo es impulsar la innovación en cada proyecto que emprendemos.",
    "¿Cómo pueden ayudar a mi negocio?": "Podemos potenciar tu negocio llevándolo al siguiente nivel digital. Desde la optimización de procesos internos hasta la creación de experiencias de usuario únicas, nuestras soluciones tecnológicas están diseñadas para aumentar tu eficiencia, alcance y competitividad en el mercado digital.",
    "¿Cuál es su enfoque en innovación?": "Nuestro enfoque en innovación se basa en estar siempre a la vanguardia de las tecnologías emergentes. Combinamos técnicas de IA, big data y desarrollo ágil para crear soluciones que no solo resuelven problemas actuales, sino que también anticipan las necesidades futuras de tu negocio.",
    "default": "Gracias por tu pregunta. En InnovativeCode, nos especializamos en proporcionar soluciones tecnológicas innovadoras y personalizadas. ¿Te gustaría que programemos una consulta para discutir cómo podemos ayudarte específicamente con tu proyecto?"
  };

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      
      // Simular respuesta del bot
      setTimeout(() => {
        const botReply = botResponses[input] || botResponses["default"];
        setMessages(msgs => [...msgs, { text: botReply, sender: 'bot' }]);
      }, 1000);
      
      setInput('');
    }
  };

  return (
    <motion.div 
      className="chatbot"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="chat-messages">
        {messages.map((msg, idx) => (
          <motion.div 
            key={idx} 
            className={`message ${msg.sender}`}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            {msg.text}
          </motion.div>
        ))}
      </div>
      <div className="predefined-questions">
        {predefinedQuestions.map((question, index) => (
          <motion.button 
            key={index} 
            onClick={() => setInput(question)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {question}
          </motion.button>
        ))}
      </div>
      <div className="chat-input">
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Escribe tu pregunta..."
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        />
        <motion.button 
          onClick={handleSend}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Enviar
        </motion.button>
      </div>
    </motion.div>
  );
};

export default Chatbot;